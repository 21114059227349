import React, { useEffect, useState } from "react";
import {
  VStack,
  HStack,
  Heading,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Box,
  IconButton,
  Switch,
  useToast,
  Text,
  CircularProgress,
} from "@chakra-ui/react";
import {
  MdNavigateNext,
  MdOutlineAdd,
  MdArrowBack,
  MdArrowDropDown,
  MdDelete,
} from "react-icons/md";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import {
  editarAsociacionCSC,
  crearAsociacionCSC,
  getSandcubes,
  editarSandcubes,
  getPlanificaciones,
} from "../../helpers/api.helper";
import moment from "moment";

const CrearEditarAsociacion = (props) => {
  const { type, onClose, initAsociaciones, mobile, asociacion } = props;
  const [planificacion, setPlanificacion] = useState([]);
  const [camionSeleccionado, setCamionSelecciondado] = useState(
    type === "editar" ? asociacion.camion : ""
  );
  const [sandcubeSeleccionado1, setSandcubeSelecciondado1] = useState(
    type === "editar" ? asociacion.sandCube1 : []
  );
  const [sandcubeSeleccionado2, setSandcubeSelecciondado2] = useState(
    type === "editar" ? asociacion.sandCube2 : []
  );
  const [sandcubeSeleccionado3, setSandcubeSelecciondado3] = useState(
    type === "editar" && asociacion.sandCube3 ? asociacion.sandCube3 : []
  );
  const [scs3, setScs3] = useState(false);
  const [filterBy, setFilterBy] = useState({
    dominio: "",
    nombreCamion: "",
    nombreSandCube: "",
    nombreSandCube2: "",
    nombreSandCube3: "",
  });
  const [scs, setScs] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const toast = useToast();
  const id = useParams();
  const fechaHoy = moment().format("YYYY-MM-DDTHH:mm");

  const initData = async () => {
    let res;
    if (type === "nueva") {
      res = await getPlanificaciones(id);

      if (res.status === 200) {
        setPlanificacion(res.data[0]);
      } else if (res.status === 400) {
        setLoading(false);
        return toast({
          title: "Error",
          description: " Error al cargar datos",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    if (type === "editar") {
      res = await getPlanificaciones({
        padLocacion: asociacion.padLocacionACSC,
      });

      if (res.status === 200) {
        setPlanificacion(res.data[0]);
      } else if (res.status === 400) {
        setLoading(false);
        return toast({
          title: "Error",
          description: " Error al cargar datos",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }

    res = await getSandcubes();

    if (res.status === 200) {
      setScs(res.data);
    } else if (res.status === 400) {
      setLoading(false);
      return toast({
        title: "Error",
        description: " Error al cargar datos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    setLoading(false);
  };

  const loadingtable = () => {
    return (
      <HStack w="100%" placeContent="center">
        <CircularProgress isIndeterminate color="brand.naranja" />
      </HStack>
    );
  };

  useEffect(() => {
    initData();
  }, []);

  const initialValues =
    type === "editar"
      ? {
          fecha: moment(asociacion.fechaCreacionACSC).format(
            "YYYY-MM-DDTHH:mm"
          ),
          planta: asociacion.planta.nombrePlanta,
          sandcubes: [
            {
              idSandCube: asociacion.sandCube1.idSandCube,
              nombreSandCube: asociacion.sandCube1.nombreSandCube,
              malla: asociacion.sandCube1.malla,
              tipoArena: asociacion.sandCube1.tipoArena,
            },
            {
              idSandCube: asociacion.sandCube2.idSandCube,
              nombreSandCube: asociacion.sandCube2.nombreSandCube,
              malla: asociacion.sandCube2.malla,
              tipoArena: asociacion.sandCube2.tipoArena,
            },
          ],
          camion: asociacion.camion.dominio,
          nombreCamion: asociacion.camion.nombreCamion,
          empresa: asociacion.camion.nombreEmpresaT,
          vueltaBaseACSC: asociacion.vueltaBaseACSC,
        }
      : {
          idPadlocacion: "",
          fecha: fechaHoy,
          planta: "",
          sandcubes: [
            { sandcube: "", malla: "", tipoArena: "" },
            { sandcube: "", malla: "", tipoArena: "" },
          ],
          camion: "",
          nombreCamion: "",
          empresa: "",
          vueltaBaseACSC: false,
        };

  if (type === "editar" && asociacion.sandCube3) {
    initialValues.sandcubes.push({
      idSC: asociacion.sandCube3.idSandCube,
      sandcube: asociacion.sandCube3.nombreSandCube,
      malla: asociacion.sandCube3.malla,
    });
  }

  const filtrocamiones = () => {
    if (planificacion.camiones) {
      if (Object.values(filterBy).every((value) => !value)) {
        return planificacion.camiones;
      }
      return planificacion.camiones.filter((camion) => {
        const { dominio } = filterBy;

        return (
          !dominio ||
          camion.dominio.toLowerCase().includes(dominio) ||
          !dominio ||
          camion.nombreCamion.toLowerCase().includes(dominio)
        );
      });
    }
  };

  const filtroSandcubes = () => {
    if (Object.values(filterBy).every((value) => !value)) {
      return scs;
    }

    return scs.filter((sandcube) => {
      const { nombreSandCube, nombreSandCube2, nombreSandCube3 } = filterBy;

      return (
        (!sandcube ||
          sandcube.nombreSandCube.includes(nombreSandCube.toUpperCase())) &&
        (!sandcube ||
          sandcube.nombreSandCube.includes(nombreSandCube2.toUpperCase())) &&
        (!sandcube ||
          sandcube.nombreSandCube.includes(nombreSandCube3.toUpperCase()))
      );
    });
  };

  return (
    <VStack w="100%">
      <HStack w="100%" pl={5} py={3}>
        {type === "nueva" && (
          <Box>
            <IconButton
              icon={<MdArrowBack />}
              variant="link"
              onClick={() => navigate(-1)}
              fontSize="24px"
            />
          </Box>
        )}
        {type === "editar" && (
          <Box>
            <IconButton
              icon={<MdArrowBack />}
              variant="link"
              onClick={() => onClose()}
              fontSize="24px"
            />
          </Box>
        )}
        <Heading
          fontWeight="medium"
          size="xl"
          fontFamily="fonts.title"
          color="brand.gris_primario"
        >
          {type === "nueva" ? "Nueva" : "Editar"} asociación Camión/Sandcubes
        </Heading>
        <Text fontSize="16px" placeSelf="end" letterSpacing={1}>
          en {planificacion.idPadlocacion}
        </Text>
      </HStack>
      <VStack w="100%" bg="brand.fondo" borderRadius={5} px={3}>
        {loading && loadingtable()}
        {!loading && (
          <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
              setLoading(true);

              if (!camionSeleccionado.idCamion) {
                setLoading(false);
                return toast({
                  title: "Error",
                  description: "Camión requerido",
                  status: "error",
                  isClosable: true,
                  duration: 3000,
                });
              }
              if (
                !sandcubeSeleccionado1.nombreSandCube ||
                !sandcubeSeleccionado2.nombreSandCube
              ) {
                setLoading(false);
                return toast({
                  title: "Error",
                  description: "Sandcubes requeridos",
                  status: "error",
                  isClosable: true,
                  duration: 3000,
                });
              }

              let sendSanduCubes;

              if (sandcubeSeleccionado3) {
                sendSanduCubes = [
                  {
                    id: sandcubeSeleccionado1.idSandCube,
                    malla: sandcubeSeleccionado1.malla,
                    tipoArena: sandcubeSeleccionado1.tipoArena,
                    nombreSandCube: sandcubeSeleccionado1.nombreSandCube,
                    condicion: sandcubeSeleccionado1.condicion,
                    toneladasSandCube: sandcubeSeleccionado1.toneladasSandCube,
                    statusSandCube: sandcubeSeleccionado1.statusSandCube,
                  },
                  {
                    id: sandcubeSeleccionado2.idSandCube,
                    malla: sandcubeSeleccionado2.malla,
                    tipoArena: sandcubeSeleccionado2.tipoArena,
                    nombreSandCube: sandcubeSeleccionado2.nombreSandCube,
                    condicion: sandcubeSeleccionado2.condicion,
                    toneladasSandCube: sandcubeSeleccionado2.toneladasSandCube,
                    statusSandCube: sandcubeSeleccionado2.statusSandCube,
                  },
                  {
                    id: sandcubeSeleccionado3.idSandCube,
                    malla: sandcubeSeleccionado3.malla,
                    tipoArena: sandcubeSeleccionado3.tipoArena,
                    nombreSandCube: sandcubeSeleccionado3.nombreSandCube,
                    condicion: sandcubeSeleccionado3.condicion,
                    toneladasSandCube: sandcubeSeleccionado3.toneladasSandCube,
                    statusSandCube: sandcubeSeleccionado3.statusSandCube,
                  },
                ];
              } else {
                sendSanduCubes = [
                  {
                    id: sandcubeSeleccionado1.idSandCube,
                    malla: sandcubeSeleccionado1.malla,
                    tipoArena: sandcubeSeleccionado1.tipoArena,
                    nombreSandCube: sandcubeSeleccionado1.nombreSandCube,
                    condicion: sandcubeSeleccionado1.condicion,
                    toneladasSandCube: sandcubeSeleccionado1.toneladasSandCube,
                    statusSandCube: sandcubeSeleccionado1.statusSandCube,
                  },
                  {
                    id: sandcubeSeleccionado2.idSandCube,
                    malla: sandcubeSeleccionado2.malla,
                    tipoArena: sandcubeSeleccionado2.tipoArena,
                    nombreSandCube: sandcubeSeleccionado2.nombreSandCube,
                    condicion: sandcubeSeleccionado2.condicion,
                    toneladasSandCube: sandcubeSeleccionado2.toneladasSandCube,
                    statusSandCube: sandcubeSeleccionado2.statusSandCube,
                  },
                ];
              }

              await editarSandcubes(sendSanduCubes);

              let res;
              if (type === "editar") {
                res = await editarAsociacionCSC({
                  id: asociacion.idACSC,
                  padLocacionACSC: asociacion.padLocacionACSC,
                  idPlanta: parseInt(values.planta),
                  idSandCube1: sandcubeSeleccionado1.idSandCube,
                  idSandCube2: sandcubeSeleccionado2.idSandCube,
                  idSandCube3: sandcubeSeleccionado3
                    ? sandcubeSeleccionado3.idSandCube
                    : null,
                  idCamion: camionSeleccionado.idCamion,
                  vueltaBaseACSC: values.vueltaBaseACSC,
                });
              } else {
                res = await crearAsociacionCSC({
                  padLocacionACSC: planificacion.idPadlocacion,
                  fechaCreacionACSC: values.fecha,
                  idPlanta: parseInt(values.planta),
                  idSandCube1: sandcubeSeleccionado1.idSandCube,
                  idSandCube2: sandcubeSeleccionado2.idSandCube,
                  idSandCube3: sandcubeSeleccionado3
                    ? sandcubeSeleccionado3.idSandCube
                    : null,
                  idCamion: camionSeleccionado.idCamion,
                  vueltaBaseACSC: values.vueltaBaseACSC,
                });
              }

              if (res.status === 200) {
                toast({
                  status: "success",
                  isClosable: true,
                  title: `Asociación ${
                    type === "editar" ? "editada" : "creada"
                  } correctamente`,
                  duration: 3000,
                });
                onClose();
                initAsociaciones();
              } else if (res.status === 204) {
                toast({
                  status: "success",
                  isClosable: true,
                  title: `Asociación ${
                    type === "editar" ? "editada" : "creada"
                  } correctamente`,
                  duration: 3000,
                });
                toast({
                  status: "warning",
                  isClosable: true,
                  title: `No hay usuarios activos para notificar`,
                  duration: 3000,
                });
                navigate(-1);
                initAsociaciones();
              } else {
                setLoading(false);
                return toast({
                  status: "error",
                  isClosable: true,
                  title: `Error al ${
                    type === "crear" ? "crear" : "editar"
                  } asociación`,
                  description:
                    res.data.error.message || "Error al editar asociación",
                });
              }
              setLoading(false);
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <>
                <Stack w="100%" px={10} pt={5}>
                  <Form id="formAsociacion">
                    <Stack spacing={mobile ? 0 : 10}>
                      <Stack pb={5} spacing={mobile ? 20 : 3} direction={"row"}>
                        <FormControl w={mobile ? "xs" : "2xs"}>
                          <FormLabel fontWeight="semibold">
                            Fecha y Hora
                          </FormLabel>
                          <Input
                            w={mobile ? "xs" : "2xs"}
                            type="datetime-local"
                            name="fecha"
                            value={fechaHoy}
                            bg="brand.gris_medio"
                            isReadOnly
                          />
                        </FormControl>

                        <FormControl w={mobile ? "xs" : "2xs"}>
                          <FormLabel fontWeight="semibold">Planta</FormLabel>

                          <Select
                            w={mobile ? "xs" : "2xs"}
                            placeholder="Nombre"
                            name="planta"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            icon={<MdArrowDropDown />}
                            value={values.planta}
                          >
                            {planificacion.detallePlantas &&
                              planificacion.detallePlantas.map((planta) => (
                                <option
                                  key={planta.idPlanta}
                                  value={planta.idPlanta}
                                >
                                  {planta.nombrePlanta}
                                </option>
                              ))}
                          </Select>
                        </FormControl>

                        <FormControl w={mobile ? "xs" : "2xs"}>
                          <FormLabel fontWeight="semibold">
                            Vuelta a base
                          </FormLabel>
                          <Switch
                            onChange={handleChange}
                            isChecked={values.vueltaBaseACSC}
                            name="vueltaBaseACSC"
                          />
                        </FormControl>
                      </Stack>
                      <Stack pb={5} spacing={3} direction={"row"}>
                        {camionSeleccionado.length < 1 && (
                          <FormControl w="2xs">
                            <FormLabel fontWeight="semibold">Camion</FormLabel>
                            <Input
                              w="xs"
                              type="text"
                              value={filterBy.dominio}
                              placeholder={`Buscar`}
                              onChange={(e) => {
                                setFilterBy((prevstate) => ({
                                  ...prevstate,
                                  dominio: e.target.value,
                                }));
                                filtrocamiones();
                              }}
                            />
                            {filterBy.dominio && (
                              <Box
                                w={"100%"}
                                position={"absolute"}
                                maxH={"2xs"}
                                overflow={"auto"}
                                bg={"white"}
                                zIndex={1000}
                                cursor={"pointer"}
                              >
                                {filtrocamiones().map((camion) => (
                                  <option
                                    key={camion.idCamion}
                                    value={camion.dominio}
                                    onClick={() =>
                                      setCamionSelecciondado(camion)
                                    }
                                  >
                                    {camion.dominio} - {camion.nombreCamion}
                                  </option>
                                ))}
                              </Box>
                            )}
                          </FormControl>
                        )}
                        {camionSeleccionado && (
                          <>
                            <FormControl w="2xs">
                              <FormLabel fontWeight="semibold">
                                Camion
                              </FormLabel>
                              <Input
                                w="2xs"
                                type="text"
                                value={camionSeleccionado.dominio}
                                readOnly
                              />
                            </FormControl>
                            <FormControl
                              placeSelf={mobile ? "end" : "start"}
                              w={"2xs"}
                            >
                              <FormLabel fontWeight="semibold">
                                Interno
                              </FormLabel>
                              <Input
                                w="2xs"
                                type="text"
                                name="nombreCamion"
                                value={camionSeleccionado.nombreCamion}
                                readOnly
                              />
                            </FormControl>
                            <FormControl
                              placeSelf={mobile ? "end" : "start"}
                              w={"2xs"}
                            >
                              <FormLabel fontWeight="semibold">
                                Empresa
                              </FormLabel>

                              {type === "editar" ? (
                                <Input
                                  w="2xs"
                                  value={camionSeleccionado.nombreEmpresaT}
                                  readOnly
                                />
                              ) : (
                                <Input
                                  w="2xs"
                                  value={
                                    camionSeleccionado.empresaTransportista
                                      .nombreEmpresaT
                                  }
                                  readOnly
                                />
                              )}
                            </FormControl>
                            <IconButton
                              size={"sm"}
                              variant={"link"}
                              icon={<MdDelete />}
                              onClick={() => setCamionSelecciondado("")}
                            />
                          </>
                        )}
                      </Stack>
                    </Stack>
                    <HStack w="100%" spacing={mobile ? 20 : 10}>
                      <Stack
                        pb={5}
                        spacing={mobile ? 20 : 3}
                        direction={mobile ? "row" : "column"}
                      >
                        {!sandcubeSeleccionado1 && (
                          <FormControl w={"2xs"}>
                            <FormLabel fontWeight="semibold">
                              Sandcube
                            </FormLabel>
                            <Input
                              w="2xs"
                              type="text"
                              value={filterBy.nombreSandCube}
                              placeholder={`Buscar`}
                              onChange={(e) => {
                                setFilterBy((prevstate) => ({
                                  ...prevstate,
                                  nombreSandCube: e.target.value,
                                }));
                                filtroSandcubes();
                              }}
                            />
                            {filterBy.nombreSandCube && (
                              <Box
                                w={"100%"}
                                position={"absolute"}
                                h={"200px"}
                                overflow={"auto"}
                                bg={"white"}
                                zIndex={1000}
                                cursor={"pointer"}
                              >
                                {filtroSandcubes().map((sandcube) => (
                                  <option
                                    key={sandcube.idSandCube}
                                    value={sandcube.nombreSandCube}
                                    onClick={() => {
                                      setSandcubeSelecciondado1(sandcube);
                                      setFilterBy((prevstate) => ({
                                        ...prevstate,
                                        nombreSandCube: "",
                                      }));
                                    }}
                                  >
                                    {sandcube.nombreSandCube}
                                  </option>
                                ))}
                              </Box>
                            )}
                          </FormControl>
                        )}
                        {sandcubeSeleccionado1 && (
                          <HStack>
                            <FormControl w={"2xs"}>
                              <FormLabel fontWeight="semibold">
                                Sandcube
                              </FormLabel>
                              <Input
                                w="2xs"
                                type="text"
                                value={sandcubeSeleccionado1.nombreSandCube}
                                placeholder={`Buscar`}
                                onChange={(e) => {
                                  setFilterBy((prevstate) => ({
                                    ...prevstate,
                                    nombreSandCube: e.target.value,
                                  }));
                                  setSandcubeSelecciondado1((prevstate) => ({
                                    ...prevstate,
                                    nombreSandCube: e.target.value,
                                  }));
                                  filtroSandcubes();
                                }}
                              />
                              {filterBy.nombreSandCube && (
                                <Box
                                  w={"100%"}
                                  position={"absolute"}
                                  maxH={"200px"}
                                  overflow={"auto"}
                                  bg={"white"}
                                  zIndex={1000}
                                  cursor={"pointer"}
                                >
                                  {filtroSandcubes().map((sandcube) => (
                                    <option
                                      key={sandcube.idSandCube}
                                      value={sandcube.nombreSandCube}
                                      onClick={() => {
                                        setSandcubeSelecciondado1(sandcube);
                                        setFilterBy((prevstate) => ({
                                          ...prevstate,
                                          nombreSandCube: "",
                                        }));
                                      }}
                                    >
                                      {sandcube.nombreSandCube}
                                    </option>
                                  ))}
                                </Box>
                              )}
                            </FormControl>
                            <FormControl
                              placeSelf={mobile ? "end" : "start"}
                              w={"2xs"}
                            >
                              <FormLabel fontWeight="semibold">Malla</FormLabel>
                              <Select
                                w={"2xs"}
                                placeholder="- Tipo -"
                                onChange={(e) => {
                                  setSandcubeSelecciondado1((prevstate) => ({
                                    ...prevstate,
                                    malla: e.target.value,
                                  }));
                                  filtroSandcubes();
                                }}
                                onBlur={handleBlur}
                                icon={<MdArrowDropDown />}
                                value={sandcubeSeleccionado1.malla}
                              >
                                <option value="100">100</option>
                                <option value="30/70">30/70</option>
                              </Select>
                            </FormControl>
                            <FormControl
                              placeSelf={mobile ? "end" : "start"}
                              w={"2xs"}
                            >
                              <FormLabel fontWeight="semibold">
                                Tipo de arena
                              </FormLabel>
                              <Select
                                w={"2xs"}
                                placeholder="- Tipo -"
                                onChange={(e) => {
                                  setSandcubeSelecciondado1((prevstate) => ({
                                    ...prevstate,
                                    tipoArena: e.target.value,
                                  }));
                                  filtroSandcubes();
                                }}
                                onBlur={handleBlur}
                                icon={<MdArrowDropDown />}
                                value={sandcubeSeleccionado1.tipoArena}
                              >
                                <option value="seca">Seca</option>
                                <option value="humeda">Humeda</option>
                              </Select>
                            </FormControl>
                            <IconButton
                              variant={"link"}
                              icon={<MdDelete />}
                              onClick={() => setSandcubeSelecciondado1()}
                            />
                          </HStack>
                        )}
                      </Stack>
                    </HStack>
                    <HStack w="100%" spacing={mobile ? 20 : 10}>
                      <Stack
                        pb={5}
                        spacing={mobile ? 20 : 3}
                        direction={mobile ? "row" : "column"}
                      >
                        {!sandcubeSeleccionado2 && (
                          <FormControl w={"2xs"}>
                            <Input
                              w="2xs"
                              type="text"
                              value={filterBy.nombreSandCube2}
                              placeholder={`Buscar`}
                              onChange={(e) => {
                                setFilterBy((prevstate) => ({
                                  ...prevstate,
                                  nombreSandCube2: e.target.value,
                                }));

                                filtroSandcubes();
                              }}
                            />
                            {filterBy.nombreSandCube2 && (
                              <Box
                                w={"100%"}
                                position={"absolute"}
                                maxH={"2xs"}
                                overflow={"auto"}
                                bg={"white"}
                                zIndex={1000}
                                cursor={"pointer"}
                              >
                                {filtroSandcubes().map((sandcube) => (
                                  <option
                                    key={sandcube.idSandCube}
                                    value={sandcube.nombreSandCube}
                                    onClick={() => {
                                      setSandcubeSelecciondado2(sandcube);
                                      setFilterBy((prevstate) => ({
                                        ...prevstate,
                                        nombreSandCube2: "",
                                      }));
                                    }}
                                  >
                                    {sandcube.nombreSandCube}
                                  </option>
                                ))}
                              </Box>
                            )}
                          </FormControl>
                        )}
                        {sandcubeSeleccionado2 && (
                          <HStack>
                            <FormControl w={"2xs"}>
                              <Input
                                w="2xs"
                                type="text"
                                value={sandcubeSeleccionado2.nombreSandCube}
                                placeholder={`Buscar`}
                                onChange={(e) => {
                                  setFilterBy((prevstate) => ({
                                    ...prevstate,
                                    nombreSandCube2: e.target.value,
                                  }));
                                  setSandcubeSelecciondado2((prevstate) => ({
                                    ...prevstate,
                                    nombreSandCube2: e.target.value,
                                  }));
                                  filtroSandcubes();
                                }}
                              />
                              {filterBy.nombreSandCube2 && (
                                <Box
                                  w={"100%"}
                                  position={"absolute"}
                                  maxH={"2xs"}
                                  overflow={"auto"}
                                  bg={"white"}
                                  zIndex={1000}
                                  cursor={"pointer"}
                                >
                                  {filtroSandcubes().map((sandcube) => (
                                    <option
                                      key={sandcube.idSandCube}
                                      value={sandcube.nombreSandCube}
                                      onClick={() => {
                                        setSandcubeSelecciondado2(sandcube);
                                        setFilterBy((prevstate) => ({
                                          ...prevstate,
                                          nombreSandCube2: "",
                                        }));
                                      }}
                                    >
                                      {sandcube.nombreSandCube}
                                    </option>
                                  ))}
                                </Box>
                              )}
                            </FormControl>
                            <FormControl
                              placeSelf={mobile ? "end" : "start"}
                              w={"2xs"}
                            >
                              <Select
                                w={"2xs"}
                                placeholder="- Tipo -"
                                onChange={(e) => {
                                  setSandcubeSelecciondado2((prevstate) => ({
                                    ...prevstate,
                                    malla: e.target.value,
                                  }));
                                  filtroSandcubes();
                                }}
                                onBlur={handleBlur}
                                icon={<MdArrowDropDown />}
                                value={sandcubeSeleccionado2.malla}
                              >
                                <option value="100">100</option>
                                <option value="30/70">30/70</option>
                              </Select>
                            </FormControl>
                            <FormControl
                              placeSelf={mobile ? "end" : "start"}
                              w={"2xs"}
                            >
                              <Select
                                w={"2xs"}
                                placeholder="- Tipo -"
                                onChange={(e) => {
                                  setSandcubeSelecciondado2((prevstate) => ({
                                    ...prevstate,
                                    tipoArena: e.target.value,
                                  }));
                                  filtroSandcubes();
                                }}
                                onBlur={handleBlur}
                                icon={<MdArrowDropDown />}
                                value={sandcubeSeleccionado2.tipoArena}
                              >
                                <option value="seca">Seca</option>
                                <option value="humeda">Humeda</option>
                              </Select>
                            </FormControl>

                            <IconButton
                              variant={"link"}
                              icon={<MdDelete />}
                              onClick={() => setSandcubeSelecciondado2()}
                            />
                          </HStack>
                        )}
                      </Stack>
                    </HStack>
                    {!scs3 && (
                      <Button
                        color="brand.naranja"
                        fontSize="12px"
                        variant="link"
                        size="lg"
                        leftIcon={<MdOutlineAdd size={18} />}
                        onClick={() => {
                          setScs3(true);
                        }}
                        textColor="black"
                      >
                        Agregar Sandcube
                      </Button>
                    )}
                    {scs3 && (
                      <HStack w="100%" spacing={mobile ? 20 : 10}>
                        <Stack
                          pb={5}
                          spacing={mobile ? 20 : 3}
                          direction={mobile ? "row" : "column"}
                        >
                          {!sandcubeSeleccionado3 && (
                            <FormControl w={"xs"}>
                              <Input
                                w="xs"
                                type="text"
                                value={filterBy.nombreSandCube}
                                placeholder={`Buscar`}
                                onChange={(e) => {
                                  setFilterBy((prevstate) => ({
                                    ...prevstate,
                                    nombreSandCube3: e.target.value,
                                  }));
                                  filtroSandcubes();
                                }}
                              />
                              {filterBy.nombreSandCube && (
                                <Box
                                  w={"100%"}
                                  position={"absolute"}
                                  maxH={"200px"}
                                  overflow={"auto"}
                                  bg={"white"}
                                  zIndex={1000}
                                  cursor={"pointer"}
                                >
                                  {filtroSandcubes().map((sandcube) => (
                                    <option
                                      key={sandcube.idSandCube}
                                      value={sandcube.nombreSandCube}
                                      onClick={() => {
                                        setSandcubeSelecciondado3(sandcube);
                                        setFilterBy((prevstate) => ({
                                          ...prevstate,
                                          nombreSandCube3: "",
                                        }));
                                      }}
                                    >
                                      {sandcube.nombreSandCube}
                                    </option>
                                  ))}
                                </Box>
                              )}
                            </FormControl>
                          )}
                          {sandcubeSeleccionado3 && (
                            <HStack>
                              <FormControl w={mobile ? "xs" : "2xs"}>
                                <Input
                                  w="xs"
                                  type="text"
                                  value={filterBy.nombreSandCube}
                                  placeholder={`Buscar`}
                                  onChange={(e) => {
                                    setFilterBy((prevstate) => ({
                                      ...prevstate,
                                      nombreSandCube3: e.target.value,
                                    }));
                                    setSandcubeSelecciondado3((prevstate) => ({
                                      ...prevstate,
                                      nombreSandCube3: e.target.value,
                                    }));
                                    filtroSandcubes();
                                  }}
                                />
                                {filterBy.nombreSandCube && (
                                  <Box
                                    w={"100%"}
                                    position={"absolute"}
                                    maxH={"2xs"}
                                    overflow={"auto"}
                                    bg={"white"}
                                    zIndex={1000}
                                    cursor={"pointer"}
                                  >
                                    {filtroSandcubes().map((sandcube) => (
                                      <option
                                        key={sandcube.idSandCube}
                                        value={sandcube.nombreSandCube}
                                        onClick={() => {
                                          setSandcubeSelecciondado3(sandcube);
                                          setFilterBy((prevstate) => ({
                                            ...prevstate,
                                            nombreSandCube3: "",
                                          }));
                                        }}
                                      >
                                        {sandcube.nombreSandCube}
                                      </option>
                                    ))}
                                  </Box>
                                )}
                              </FormControl>

                              <FormControl
                                placeSelf={mobile ? "end" : "start"}
                                w={"2xs"}
                              >
                                <Select
                                  w={"2xs"}
                                  placeholder="- Tipo -"
                                  onChange={(e) => {
                                    setSandcubeSelecciondado3((prevstate) => ({
                                      ...prevstate,
                                      malla: e.target.value,
                                    }));
                                    filtroSandcubes();
                                  }}
                                  onBlur={handleBlur}
                                  icon={<MdArrowDropDown />}
                                  value={sandcubeSeleccionado3.malla}
                                >
                                  <option value="100">100</option>
                                  <option value="30/70">30/70</option>
                                </Select>
                              </FormControl>
                              <FormControl
                                placeSelf={mobile ? "end" : "start"}
                                w={"2xs"}
                              >
                                <Select
                                  w={"2xs"}
                                  placeholder="- Tipo -"
                                  onChange={(e) => {
                                    setSandcubeSelecciondado3((prevstate) => ({
                                      ...prevstate,
                                      tipoArena: e.target.value,
                                    }));
                                    filtroSandcubes();
                                  }}
                                  onBlur={handleBlur}
                                  icon={<MdArrowDropDown />}
                                  value={sandcubeSeleccionado3.tipoArena}
                                >
                                  <option value="seca">Seca</option>
                                  <option value="humeda">Humeda</option>
                                </Select>
                              </FormControl>

                              <IconButton
                                variant={"link"}
                                icon={<MdDelete />}
                                onClick={() =>
                                  setSandcubeSelecciondado1({
                                    nombreSandCube: "",
                                    malla: "",
                                    tipoArena: "",
                                  })
                                }
                              />
                            </HStack>
                          )}
                        </Stack>
                      </HStack>
                    )}
                  </Form>
                </Stack>

                <HStack w="100%" justifyContent="end" py={2} pr={2}>
                  <VStack>
                    <Button
                      fontSize={12}
                      fontWeight="semibold"
                      bg="brand.naranja"
                      color="white"
                      rightIcon={<MdNavigateNext fontSize="20px" />}
                      variant="solid"
                      form="formAsociacion"
                      onClick={handleSubmit}
                      isDisabled={loading}
                      _hover={{ bg: "white", color: "brand.naranja" }}
                    >
                      Finalizar
                    </Button>
                  </VStack>
                </HStack>
              </>
            )}
          </Formik>
        )}
      </VStack>
    </VStack>
  );
};

export default CrearEditarAsociacion;
